<template>
  <div class="container">
    <PublicHeader
      :totalName="title"
      :rightRouter="rightRouter"
      :rightTitle="rightTitle"
    />
    <div class="allProfit" v-if="!rightRouter">
      {{ $t('Team').TotalProfit }}：{{ basicConfig.Symbol }}{{ ChildsProfit }}
    </div>
    <van-pull-refresh
      v-model="refreshing"
      :pulling-text="$t('public').pullingText"
      :loosing-text="$t('public').ReleaseText"
      :loading-text="$t('public').loadingText"
      :success-text="$t('public').RefreshSucceeded"
      @refresh="onRefresh"
    >
      <van-list
        class="list-box"
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
      >
        <van-row class="title" type="flex">
          <van-col span="6" v-for="(item, index) in titleList" :key="index">
            {{ item }}
          </van-col>
        </van-row>
        <van-row
          class="list-item"
          type="flex"
          v-for="(lItem, lIndex) in list"
          :key="lIndex"
        >
          <van-col span="6">
            {{ lItem.NickName }}
          </van-col>
          <van-col span="6" @click="handleGo(lItem)">
            <span style="color: #07c160">{{ lItem.ChildNum }}</span>
          </van-col>
          <van-col span="6">
            {{ lItem.ChildsProfit }}
          </van-col>
          <van-col span="6">
            {{ lItem.ParentProfit }}
          </van-col>
        </van-row>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      activeIndex: 0,
      routerName: 'Me',
      title: this.$t('Team').title,
      titleList: [
        this.$t('Team').NickName,
        this.$t('Team').TeamNum,
        this.$t('Team').ChildsProfit,
        this.$t('Team').ParentProfit,
      ],
      list: [],
      form: {
        id: null,
        page: 1,
        size: 10,
      },
      ChildsProfit: null,
      rightRouter: null,
      rightTitle: null,
      loading: false,
      finished: false,
      refreshing: false,
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  mounted() {
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id
      this.title = this.$route.query.NickName + this.$t('Team').subordinateTitle
      this.rightRouter = 'Me'
      this.rightTitle = '我的'
    } else {
      this.getuserInfo()
    }
  },
  methods: {
    async getuserInfo() {
      const res = await userApi.userInfo()
      this.ChildsProfit = res.ChildsProfit
    },
    handleGo(row) {
      if (row.ChildNum) {
        this.$router.push({
          path: '/team',
          query: { NickName: row.NickName, id: row.Id },
        })
      }
    },
    async getList() {
      const res = await userApi.childs(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }

      this.loading = false
    },
    onRefresh() {
      this.form.page = 1
      this.finished = false
      this.loading = true
      setTimeout(() => {
        this.list = []
        this.getList()
      }, 100)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size: 14px
  color #ebe5cd
  overflow-x hidden
  height 100vh
  padding-top 48.5px
  text-align center
  .allProfit
    text-align left
    padding 20px 30px
  .title
    font-size 13px
    height 37px
  .van-col
    flex-center()
  .list-box
    height calc(100vh - 117px)
    overflow scroll
  .list-item
    height 44px
    font-size 12px
    color #747269
    .fail
      color #ff0000
    .success
      color #fae39c
</style>
